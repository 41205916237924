
import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders,HttpErrorResponse,HttpResponse,HttpRequest} from "@angular/common/http";

import serviceConfig from '../service-config';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectAuthState } from '../../core/auth/_selectors/auth.selectors';
import { AppState } from '../../core/reducers/index';
import * as jwt_decode from 'jwt-decode';
import { BillVoucherModel } from '../../models/billvoucher';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  uri = serviceConfig.altBaseUrl ;
  billuri = serviceConfig.baseUrl + serviceConfig.endPoints.billVoucher;
  headers = new HttpHeaders()
  altHeaders: HttpHeaders;
  userId:'';
  clientName:'';
  customerId:'';

  constructor(private http:HttpClient, private store: Store<AppState>) { 
    this.store.select(selectAuthState).subscribe((result) => {  
      this.headers = new HttpHeaders().set('Authorization', "Bearer "+result.authToken);

      var decoded = jwt_decode(result.authToken); 
      //real userid.
      this.userId=decoded['https://mardom.com/clientId'];
      var decodediD = jwt_decode(result.idToken); 
      
      this.clientName=decodediD['name'];
      this.customerId=decodediD['sub'];
      
      this.altHeaders =new HttpHeaders()
                    .append('Authorization', "Bearer "+result.authToken)  
                    .append('Content-Type', 'application/json')
                    .append('accept','*/*')
                    .append('Allow', '*')
                    .append('Access-Control-Allow-Origin', '*')
                    .append('Access-Control-Allow-Methods', 'POST, GET, PUT, DELETE, OPTIONS')
                    .append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept, Referer, User-Agent')
                    .append('accept','text/plain');
    });
  }

  addBillVoucher(voucherModel:BillVoucherModel){
    voucherModel.user_id=this.userId;
    voucherModel.client_name=this.clientName;
    voucherModel.customer_id=this.customerId;
    
    return this.http.post(`${this.billuri}`, voucherModel, { headers: this.altHeaders });
  }

  getPendingInvoicesdownload(page: number, invoiceNumber: string, startDate: any, endDate:any ):Observable<any> {
    //Prod    
    let url= this.uri + serviceConfig.endPoints.pendingInvoiceDownload;
    return this
      .http
      .get<any>(`${url}?page=${page}&invoiceNumber=${invoiceNumber}&startDate=${startDate}&endDate=${endDate}`,
       { headers: this.altHeaders, responseType: 'blob' as 'json' });
    //MinimalAPI
    /*let url = "https://localhost:44348/InvoicesDownload"*
    return this.http
    .get<any>(`${url}?page=${page}`, 
    {headers: this.altHeaders, responseType: 'blob' as 'json'});*/
  }

  getPendingInvoices(page: number,pageSize:number, invoiceNumber: string, payload):Observable<any> {    
    //let url= "https://localhost:44317" + serviceConfig.endPoints.pendingInvoice;
    //PROD
    let url= this.uri + serviceConfig.endPoints.pendingInvoice;
    return this
      .http
      .get<any>(`${url}?page=${page}&pageSize=10&invoiceNumber=${invoiceNumber}&startDate=${payload.startDate}&endDate=${payload.endDate}&Status=${payload.Status}&refrencetype=${payload.refrencetype}&Refrenceno=${payload.Refrenceno}`,
       { headers: this.altHeaders });
    //Minimal
    /*let url = "https://localhost:44348/GetInvoice"
    const params: {[key: string] : string | number} = {
      status: payload.Status,
      referenceType: payload.refrenceType,
      referenceNo: payload.Refrenceno,
      page,
      pageSize: 10,
      invoiceNumber
    };
    if(payload.startDate){
      params.startDate = payload.startDate;
    }
    if(payload.endDate){
      params.endDate = payload.endDate;
    }
    const queryParams = new URLSearchParams(params as any).toString();
    return this.http.get<any>(`${url}?${queryParams}`, {headers: this.headers})*/
  }

  

  downloadBill(id:number): Observable<any>{
    let url= this.uri + serviceConfig.endPoints.downloadInvoiceId;
		return this.http.get(`${url}/${id}`, { headers: this.altHeaders, responseType:'blob'});
  }

  downloadDynamicBill(id:number): Observable<any>{
    let url= this.uri + serviceConfig.endPoints.downloadInvoiceDynamic;    
		return this.http.get(`${url}/${id}`, { headers: this.altHeaders, responseType:'blob'});
  }

  downloadDynamicBillNew(clientid,rnc,id:number):Observable<any>{
    //Prod
    let url= this.uri + '/invoice-api/invoices/getInvoicePdf'+'?clientid='+clientid+'&rnc='+rnc+'&invoiceNumber='+id;    
		return this.http.get(`${url}`, { headers: this.altHeaders,responseType: 'text' as const});
    //Minimal
    /*let url = "https://localhost:44348/blob" +'?clientId=000000037'+'&rnc=101790581'+'&invoiceNumber=FAC355218'; 
    this.http.get(`${url}`, { headers: this.altHeaders, responseType: 'blob' as const }).subscribe({
      next: (response) => {
        // Crear un enlace dinámico para descargar el archivo
        const blob = new Blob([response], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `facturas.pdf`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url); // Limpiar URL
      },
      error: (error) => {
        console.error('Error al descargar el archivo:', error);
      }
    });*/
  }

  downloadBillFreightForward(id:string):Observable<any> {
    let url= this.uri + serviceConfig.endPoints.downloadInvoiceFF;    
    return this.http.get(`${url}/${id}`, { headers: this.altHeaders, responseType:'blob'});
  }
  
}