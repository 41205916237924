import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Action } from '../../../../models/enums/action.enum';
import { Screen } from '../../../../models/enums/screen.enum';
import { invoiceResult } from '../../../../models/response-result.model';
import { InvoiceService } from '../../../../services/invoice/invoice.service';
import * as jwt_decode from 'jwt-decode';
import { ToastrService } from 'ngx-toastr';
import { LogService } from '../../../../services/log/log.service';
import { LogDto } from '../../../../models/dto/log.dto';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
import { selectAllPermissions } from '../../../../core/auth';
import { PermissionWeb } from '../../../../core/auth/_models/permission.web.model';
import { selectAuthState } from '../../../../core/auth/_selectors/auth.selectors';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ServicefileService } from '../../../../services/files/servicefile.service';
import { ServiceFileModel } from '../../../../models/servicefile';
import { PowerBILogService } from '../../../../services/log/powerBILog.service';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent implements OnInit {
  canAttachDocument: Boolean;
  canSeeAttachedDocuments: Boolean;
  canPrintInvoice: Boolean;
  @Input() isView: Boolean = true;

  permissions: PermissionWeb[];
  invoices: invoiceResult<any> = null;
  invoice: any;
  voucher;
  page: number = 1;
  invoiceNumberField: string = ''
  public startDate: any = '';
  public endDate: any = '';
  public Status: any;
  public Refrenceno: any;
  public refrencetype: any;

  public FilterForm: FormGroup;
  currentPage: number = 1;
  pageSize: number = 5;
  start: number = 0;
  end: number = this.pageSize;
  payload$: any;
  public Coordinator: boolean = false
  public role: any = [];
  public actionId: any;
  uploadedFiles: ServiceFileModel[] = [];
  public module: any;
  public objectType: any;
  public objectName: any;
  public clickEvent: any;
  public action: any;
  public screenId: any;
  public referenceNo: any;
  public referenceType: any;

  constructor(private invoiceService: InvoiceService,
    private logService: LogService,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private chRef: ChangeDetectorRef,
    private toastr: ToastrService,
    private store: Store<AppState>,
    public sFileService: ServicefileService, private powerBIService: PowerBILogService) { }


  ngOnInit() {
    this.genrateForm();
    this.payload$ = this.store.pipe(select(selectAuthState));

    this.getPendingInvoices();
    this.store.select(selectAllPermissions).subscribe(
      res => {
        this.permissions = res;
        this.checkPermissions();
      }
    )
    this.screenId = Screen.TusFacturas;
    this.module = "TusFacturas";
    this.clickEvent = "Click";
    this.action = "Redirect";
    this.objectType = "Button";
    this.objectName = "Tus Facturas";
    this.integrateAuditApi()
  }

  integrateAuditApi() {
    this.powerBIService.createPayload(this.screenId, this.module, this.clickEvent, this.action, this.objectType, this.objectName, this.referenceNo, this.referenceType, null);
  }


  checkPermissions() {
    this.canAttachDocument = this.permissions.findIndex(item => item.name === 'canAttachDocument') > -1;
    this.canSeeAttachedDocuments = this.permissions.findIndex(item => item.name === 'canSeeAttachedDocuments') > -1;
    this.canPrintInvoice = this.permissions.findIndex(item => item.name === 'canPrintInvoice') > -1;
  }

  nextPage() {
    this.start += this.pageSize;
    this.end += this.pageSize;
    this.currentPage++;
  }

  previousPage() {
    this.start -= this.pageSize;
    this.end -= this.pageSize;
    this.currentPage--;
  }

  onPageChange(item: number) {
    this.page = item
    this.getPendingInvoices();
    this.chRef.detectChanges();
  }

  getPendingInvoices() {
    let payload = {
      startDate: this.FilterForm.controls.startDate.value,
      endDate: this.FilterForm.controls.endDate.value,
      Status: this.FilterForm.controls.status.value,
      refrencetype: this.FilterForm.controls.refrenceType.value,
      Refrenceno: this.FilterForm.controls.Refrenceno.value
    }
    this.invoiceService
      .getPendingInvoices(this.page, this.pageSize, this.invoiceNumberField, payload)
      .subscribe((data) => {
        this.invoices = data;
        this.chRef.detectChanges();
      },
        err => {
          if (err.status == 400) {
            this.invoices = { results: <any>[], total: 0 };
            this.chRef.detectChanges();
          }
        });
  }

  searchInvoice() {
    this.page = 1;
    this.getPendingInvoices()
  }

  searchProduct() {
    this.page = 1
    let startDate = this.FilterForm.controls.startDate.value;
    let endDate = this.FilterForm.controls.endDate.value;
    let Status = this.FilterForm.controls.status.value;
    let refrencetype = this.FilterForm.controls.refrenceType.value;
    let Refrenceno = this.FilterForm.controls.Refrenceno.value;
    if (refrencetype && !Refrenceno) {
      this.toastrService.error("Se requiere número de referencia");
    }
    else if ((this.startDate || this.endDate) && (this.startDate && this.endDate) && (this.startDate > this.endDate)) {
      this.toastrService.info("La fecha en 'Fecha Hasta' debe de ser mayor a 'Fecha Desde'.");
    } else {
      this.getPendingInvoices()
    }
    this.clickEvent = "Click";
    this.action = "Search";
    this.objectType = "Search Category";
    this.objectName = "Buscar";
    this.referenceNo = Refrenceno;
    this.referenceType = refrencetype;
    this.integrateAuditApi();
  }


  downloadInventeryProduct() {
    this.clickEvent = "Click";
    this.action = "Download";
    this.objectType = "Button";
    this.objectName = "Descargar Excel";
    this.integrateAuditApi();
    if ((this.startDate || this.endDate) && (this.startDate && this.endDate) && (this.startDate > this.endDate)) {
      this.toastrService.info("La fecha en 'Fecha Hasta' debe de ser mayor a 'Fecha Desde'.");
    } else {
      this.invoiceService.getPendingInvoicesdownload(this.page, this.invoiceNumberField, this.startDate, this.endDate).subscribe((data) => {
        console.log(data)
        const url = window.URL.createObjectURL(data);
        var fileLink = document.createElement('a');
        fileLink.href = url;
        fileLink.download = 'Mis facturas.xlsx';
        fileLink.click();

        this.chRef.detectChanges();
      });
    }
  }

  genrateForm() {
    this.FilterForm = this.formBuilder.group({
      status:'all',
      startDate: '',
      endDate: '',
      refrenceType: '',
      Refrenceno: ''
    });
  }

  getInventoryByProduct() {
    this.clickEvent = "Click";
    this.action = "Reset Info";
    this.objectType = "Button";
    this.objectName = "Limpiar";
    this.referenceNo = null;
    this.referenceType = null;
    this.integrateAuditApi();
    this.page = 1
    this.FilterForm.controls.startDate.setValue('');
    this.FilterForm.controls.endDate.setValue('');
    this.FilterForm.controls.Refrenceno.setValue('');
    this.FilterForm.controls.refrenceType.setValue('');
    this.FilterForm.controls.status.setValue('');
    this.getPendingInvoices()
  }

  downloadInvoice(invoice) {
    this.invoice = invoice;
    this.clickEvent = "Click";
    this.action = "Download";
    this.objectType = "Button";
    this.objectName = "imprimir factura";
    this.referenceNo = invoice.number;
    this.referenceType = "Invoice Number";
    this.integrateAuditApi();
    if (invoice.isFromDynamic) {
      this.invoiceService.downloadDynamicBillNew(this.invoice.clientId, this.invoice.rnc, this.invoice.number).subscribe(response => {
        // const url = window.URL.createObjectURL(response);
        if (response == null || response == "") {
          console.log('error')
          this.toastr.error('Error al descargar el archivo');
        } else {
          var fileLink = document.createElement('a');
          fileLink.setAttribute('target', '_blank');
          fileLink.href = response;
          fileLink.click();
        }
      })
    }
    /*if(invoice.dynamics) {
      this.invoiceService.downloadDynamicBillNew(this.invoice.clientId, this.invoice.rnc, this.invoice.number)
    }*/
    else if (invoice.company == 'FreightForward') {
      this.invoiceService.downloadBillFreightForward(this.invoice.number).subscribe(response => {
        if (response == null || response == "") {
          console.log('error');
          this.toastr.error('Error al descargar el archivo');
        } else {
          const url = window.URL.createObjectURL(response);
          var fileLink = document.createElement('a');
          fileLink.setAttribute('target', '_blank');
          fileLink.href = url;
          fileLink.click();
        }

      }), error => console.log('Error downloading the file'),
        () => console.info('File downloaded successfully');
    } else {
      this.invoiceService.downloadBill(this.invoice.id).subscribe(response => {
        if (response == null || response == "") {
          console.log('error');
          this.toastr.error('Error al descargar el archivo');
        } else {
          const url = window.URL.createObjectURL(response);
          var fileLink = document.createElement('a');
          fileLink.setAttribute('target', '_blank');
          fileLink.href = url;
          fileLink.click();
        }
        //window.open(url);    
      }), error => console.log('Error downloading the file'),
        () => console.info('File downloaded successfully');
    }
  }

  getUploadedDocuments(model, type) {
    this.clickEvent = "Click";
    this.action = "Open";
    this.objectType = "Button";
    this.objectName = "Ver documentos adjuntos";
    this.referenceNo = model.number;
    this.referenceType = "Invoice Number";
    this.integrateAuditApi();
    let id = 0;
    if (type == "invoice") {
      this.invoice = model;
      id = this.invoice.id;
    } else {
      this.voucher = model;
      id = this.voucher.id;
    }

    this.sFileService.getServiceFiles(type, id).subscribe((result) => {

      this.uploadedFiles = result;
      //this.rerender();
      this.chRef.detectChanges();
    });
  }

}