import { Component, ViewChild, TemplateRef, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { InvoiceService } from '../../../services/invoice/invoice.service';
import { DashboardServicesService } from '../../../services/dashboard-services/dashboard-services.service';
import { Observable } from 'rxjs';
import { LogDto } from '../../../models/dto/log.dto';
import { Action } from '../../../models/enums/action.enum';
import { LogService } from '../../../services/log/log.service';
import { Screen } from '../../../models/enums/screen.enum';
import * as jwt_decode from "jwt-decode";
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../core/reducers';
import { selectAuthState } from '../../../core/auth/_selectors/auth.selectors';
import { orderInProgressService } from '../orders-in-progress/orders-in-progress.service';
import { TrackingServiceService } from '../../../services/tracking-service.service'

import { url } from 'inspector';
import { Subscription } from 'rxjs/Subscription';
import { DatePipe } from '@angular/common';
import { PowerBILogService } from '../../../services/log/powerBILog.service';

declare var $: any;

@Component({
  selector: 'kt-share-order',
  templateUrl: './share-order.component.html',
  styleUrls: ['./share-order.component.scss']
})
export class ShareOrderComponent implements OnInit {
  @ViewChild('impedimento', { static: true }) impedimento: TemplateRef<any>;
  @ViewChild('sharebl', { static: true }) sharebl: TemplateRef<any>;
  @ViewChild('sharedata', { static: true }) sharedata: TemplateRef<any>;
  @ViewChild('calificar', { static: true }) calificar: TemplateRef<any>;
  public viewdetail = false;
  public expandupdates = false;
  public blexpandupdates = true;
  public dropdownexpand = false;
  public sharelink: any;
  public isChecked: any;
  public indexno: any;
  public tarjaIndexno = 0;
  public innerindexno: any;
  public billofladingdropdown = false;
  public tarjaDetilsDropdown = true;
  public bookmarkactive = false;
  public active = false;
  public tarjaDetilsActive = true;
  public innerindexactive = false;
  public rightsidepanel = false;
  public almacengeneral = false;
  public gestionaduanal = false;
  public almacendesconsolidacion = false;
  public transpterrestre = false;
  public transpaereo = false;
  public transpmaritima = false;
  public bls: any = [];
  public blSelected: string = '';
  public blData: any = {}
  public blErrors: any = {}
  public searchForm: FormGroup;
  public orderInProgressList: any = [];
  public page: number = 1;
  public invoicePage: number = 1;
  public notRows: boolean;
  public impedementActiveIndex: any;
  public selectedData: any;
  public selectedTarjaData: any;
  public title: any;
  public status: "";
  public containersList: any = [];
  public tarjasList: any = [];
  public customesList: any = [];
  public wisRefrencesList: any = [];
  public windipList: any;
  public poList: any;
  public blStatusDetils: any;
  public blStatusDetilsHorizontal: any;
  public blThStatusDetils: any = [];
  public invoices: any;
  public payload$: Observable<any>;
  public actionId: any;
  public sortOrder: any;
  public orderon: any = 'dt';
  public size: number = 10;
  public search: any = '';
  public getSystem: any;
  public allStatus: any;
  public firstDate: any;
  public lastDate: any;
  public transportData: any;
  public transpTerrestreDetils: any;
  public transpTerrestreStatus = [];
  public selectedTraja: any;
  public associatedBls = [];
  public tarjaDetils: any;
  public blHijoNumber: any;
  public documentList = [];
  public blNumber: any = '';
  public sharedtype: any;
  ratingcomments: boolean = false;
  public comments: any;
  blnum: any;
  ratingstar: any = {};
  public commentsdisable: boolean = false;
  ratingstardisable: boolean = false;
  subscription: Subscription;
  poPort = '';
  public selectedCompanyID;


  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private dashboardServices: TrackingServiceService,
    private changeDetectorRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private invoiceService: InvoiceService,
    private store: Store<AppState>,
    private logService: LogService,
    private router: Router,
    private orderinprogress: orderInProgressService,
    private powerBIService: PowerBILogService,
    private datePipe: DatePipe
  ) {
    $('body').tooltip({ selector: '[data-toggle=tooltip]' });
    this.subscription = this.orderinprogress.getData().subscribe(x => {
      // this.Calificarbtn = x.calificar
      this.blnum = x.blnumber
    });
  }

  numSequence(n: number): Array<number> {
    return Array(n);
  }

  ngOnInit() {
    this.genrateForm();
    this.route.params.subscribe(params => {
      this.title = 'Localiza tus Cargas'
      this.searchForm.controls.Refrenceno.setValue(params.type);
      // this.sharedtype = params.sharing;
      if (params.sharing) {
        this.searchForm.controls.refrenceType.setValue(params.sharing);
      }
    })
    this.selectedCompanyID = localStorage.getItem("SelectedcompanyID");
    if (this.title == 'Favoritos') {
      this.getFavoritos()
    } else {
      this.getOrderInProgressList();
    }
    this.integrateAuditApi();
  }

  integrateAuditApi() {
    this.payload$ = this.store.pipe(select(selectAuthState));
    this.payload$.subscribe(res => {
      let tokenData = jwt_decode(res.authToken)
      this.logService.postWithPayload(<LogDto>{
        ActionId: this.actionId,
        ScreenId: Screen.IniCio,
        Json: '',
        UserName: res.user.fullname,
        Email: res.user.email,
        ClientId: tokenData['https://mardom.com/clientId'] == '' ? null : tokenData['https://mardom.com/clientId'],
        RoleName: tokenData['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'][0]
      },
        res.authToken);
    })
  }

  copylink(): void {
    navigator.clipboard.writeText(this.sharelink).then(res => {
      this.toastrService.success('Copied to Clipboard')
      this.cancel()
    }).catch(() => {
      console.error("Unable to copy text");
    });
  }

  formatDate(date: Date): string {
    let formatted = this.datePipe.transform(date, 'd MMM yyyy h:mm a') || '';
    formatted = formatted.replace(/\.\s*/g, '').toUpperCase();

    return formatted.replace(/(\d{1,2} [A-Za-z]{3})(\d{4})/, '$1 $2');
  }

  counter(i: number) {
    return new Array(i);
  }

  changeImpedementOnList(index, order) {
    this.impedementActiveIndex = index;
    this.selectedData = order;
    this.modalService.open(this.impedimento, {
      size: 'm', backdrop: 'static', centered: true
    });
  }

  changeImpedementOnTarjaList(index, tarja) {
    this.impedementActiveIndex = index;
    this.selectedTarjaData = tarja;
    this.modalService.open(this.impedimento, {
      size: 'm', backdrop: 'static', centered: true
    });
  }

  openDetailsBill(i: any, order) {
    if (this.selectedData && this.selectedData.blNumber == order && order.blNumber) {
      this.selectedData = order;
    } else {
      this.selectedData = order;
      this.containersList = [];
      this.tarjasList = [];
      this.customesList = [];
      this.wisRefrencesList = [];
      if (!this.invoices) {
        this.getInvoices(order.blNumber);
      }
      this.dashboardServices.getAllRefrences(order.blNumber).subscribe(response => {
        this.associatedBls = []
        this.associatedBls = response.associatedBls
        this.containersList = response.containers.containers;
        this.tarjasList = response.tarjas.tarjas
        //this.selectedTraja = this.tarjasList?this.tarjasList[0]:''
        this.customesList = response.customs.customs;
        this.windipList = response.windipId;
        this.wisRefrencesList = response.wisRefrences;
        this.documentList = response.documentos;
        this.poList = response.ponumber
        this.changeDetectorRef.detectChanges();
      }, (error) => {
        this.toastrService.error('Ha ocurrido un error, favor intente de nuevo. Si el error persiste , comunicarse al correo mardomgo@mardom.com');
      })

    }
    this.billofladingdropdown = this.indexno != i ? true : !this.billofladingdropdown
    this.active = this.indexno != i ? true : !this.active;
    this.indexno = i;
    this.rightsidepanel = this.active ? true : false;
    // localStorage.setItem('calificarbtn' , JSON.stringify(this.rightsidepanel))
    this.orderinprogress.sendData(
      {
        calificar: this.rightsidepanel,
        blnumber: order.blNumber
      }
    );
  }

  downloadDocumnet(doc) {
    let filepath = doc.documentpath.split(/\\\\(.*)/s)
    let netpath = doc.documentpath.split('\\');
    let filepaths = "\\" + filepath[1].split('\\').slice(1).join('\\')
    if (doc.system == 'WINDIP') {
      this.dashboardServices.downloadWindipDocumnet("\\" + netpath[2], filepaths).subscribe(response => {
        const url = window.URL.createObjectURL(response);
        var fileLink = document.createElement('a');
        fileLink.href = url;
        fileLink.download = doc.documentname;
        fileLink.click();
      }),
        error => console.log('Error downloading the file'),
        () => console.info('Archivo descargado exitosamente');
    } else {
      this.dashboardServices.downloadDocumnet(doc.documentid).subscribe(response => {
        const url = window.URL.createObjectURL(response);
        var fileLink = document.createElement('a');
        fileLink.href = url;
        fileLink.download = doc.documentname;
        fileLink.click();
      }, (error) => {
        this.toastrService.info('Archivo no encontrado');
      })
    }

  }

  getInvoices(blnum: any) {
    this.dashboardServices.getInvoicesbyBl(this.invoicePage, blnum).subscribe(response => {
      this.invoices = response.results;
      this.invoices['total'] = response.total
      this.changeDetectorRef.detectChanges();
    }, (error) => {
      this.invoices = ''
    })
    // this.invoiceService
    //     .getPendingInvoices(this.invoicePage, '')
    //     .subscribe((data) => {
    //       this.invoices = data.results;
    //       this.invoices['total'] = data.total
    //       this.changeDetectorRef.detectChanges();
    //     }, (error) => {
    //       this.toastrService.error('Ha ocurrido un error, favor intente de nuevo. Si el error persiste , comunicarse al correo mardomgo@mardom.com');
    //     })
  }

  onPageChangeInvoice(event) {
    this.invoicePage = event;
    this.getInvoices(this.selectedData.blNumber);
  }

  downloadInvoice(invoice) {
    if (invoice.isFromDynamic) {
      this.invoiceService.downloadDynamicBillNew(invoice.clientId, invoice.rnc, invoice.number).subscribe(response => {
        // const url = window.URL.createObjectURL(response);
        var fileLink = document.createElement('a');
        fileLink.setAttribute('target', '_blank');
        fileLink.href = response;
        fileLink.click();
      })
    } else if (invoice.company == 'FreightForward') {
      this.invoiceService.downloadBillFreightForward(invoice.number).subscribe(response => {
        const url = window.URL.createObjectURL(response);
        //window.open(url);    
        var fileLink = document.createElement('a');
        fileLink.setAttribute('target', '_blank');
        fileLink.href = url;
        fileLink.click();
        //window.open(url,sfile.file_type);    

      }), error => console.log('Error downloading the file'),
        () => console.info('File downloaded successfully');
    } else {
      this.invoiceService.downloadBill(invoice.id).subscribe(response => {

        const url = window.URL.createObjectURL(response);
        var fileLink = document.createElement('a');
        fileLink.setAttribute('target', '_blank');
        fileLink.href = url;
        fileLink.click();
        //window.open(url);    
      }), error => console.log('Error downloading the file'),
        () => console.info('File downloaded successfully');
    }
  }


  openbilloflading(i: any) {
    this.indexno = i
    this.billofladingdropdown = !this.billofladingdropdown
    this.active = !this.active
  }

  openTarjaDetils(i: any, tarja) {
    if (this.blHijoNumber != tarja.blNumber) {
      this.blHijoNumber = tarja.blNumber
      this.getBlDetailsStatus()
    }
    this.tarjaDetilsDropdown = this.tarjaIndexno != i ? true : !this.tarjaDetilsDropdown
    this.tarjaDetilsActive = this.tarjaIndexno != i ? true : !this.tarjaDetilsActive;
    this.tarjaIndexno = i
  }

  openTranspTerrestreDetils(i, data) {
    let system = this.allStatus.systems
    this.transpTerrestreDetils = data.conduceDetails
    this.transpTerrestreDetilsStatus()
    this.billofladingdropdown = this.indexno != i ? true : !this.billofladingdropdown
    //this.active = this.indexno != i ? true : !this.active;
    this.indexno = i;
    this.innerindexno = -1;
    this.changeDetectorRef.detectChanges();
    // this.dashboardServices.getTranspTerrestreDetils(data.solicitudTransporte, system).subscribe(response => {
    //   this.transpTerrestreDetils = response;
    //   this.transpTerrestreDetilsStatus()
    //   this.billofladingdropdown = this.indexno != i ? true : !this.billofladingdropdown
    //   //this.active = this.indexno != i ? true : !this.active;
    //   this.indexno = i;
    //   this.innerindexno = -1;
    //   this.changeDetectorRef.detectChanges();
    // }, (error) => {
    //   this.toastrService.error('Ha ocurrido un error, favor intente de nuevo. Si el error persiste , comunicarse al correo mardomgo@mardom.com');
    // })
  }

  transpTerrestreDetilsStatus() {
    this.transpTerrestreDetils.forEach(element => {
      if (element.retirementDate || element.fechaplanificada) {
        this.transpTerrestreStatus.push({
          status: 'Transporte Terrestre Planificado',
          date: element.retirementDate
        })
      }
      if (element.fecharetorno) {
        this.transpTerrestreStatus.push({
          status: 'Retorno',
          date: element.fecharetorno
        })
      }
      if (element.retirementDate || element.fechafinalizada) {
        this.transpTerrestreStatus.push({
          status: 'En Transito A Destino Final',
          date: element.returnDate
        })
      }
      if (element.finalGpsDate) {
        this.transpTerrestreStatus.push({
          status: 'Transporte Entregado',
          date: element.finalGpsDate
        })
      }
      this.transpTerrestreStatus = this.transpTerrestreStatus.sort((a, b) => {
        return <any>new Date(b.date) - <any>new Date(a.date);
      });
      element['status'] = this.transpTerrestreStatus;
      this.transpTerrestreStatus = [];
    });
  }


  openTranspTerrestreDetilsStatus(data: any, i: any) {
    this.blThStatusDetils = [];
    this.dropdownexpand = this.innerindexno != i ? true : !this.dropdownexpand
    this.innerindexactive = this.innerindexno != i ? true : !this.innerindexactive;
    this.innerindexno = i
    this.thHorizontalDetails();
    this.changeDetectorRef.detectChanges();
  }

  thHorizontalDetails() {
    this.transpTerrestreDetils.forEach((element, index) => {
      if (this.dropdownexpand && this.innerindexactive && (this.innerindexno == index)) {
        this.blThStatusDetils = this.transpTerrestreDetils[index].tracking;
      }
    })

  }

  bookmark(i: any) {
    this.indexno = i
    this.bookmarkactive = !this.bookmarkactive
  }

  getBlDetailsStatus() {
    this.almacengeneral = false;
    this.gestionaduanal = false;
    // this.almacendesconsolidacion = false;
    this.transpterrestre = false;
    this.transpaereo = false;
    this.transpmaritima = false;
    this.tarjaDetilsDropdown = true
    this.tarjaDetilsActive = true
    this.tarjaIndexno = 0
    /*  let tarja:any
     if (this.selectedTraja) {
       Object.entries(this.associatedBls).forEach(([key, value]) => {
         if (key == this.selectedTraja) {
           tarja = value
         }
       });
     } */
    this.dashboardServices.getDetailsBlStatus(this.blHijoNumber ? this.blHijoNumber : this.selectedData.blNumber).subscribe(response => {
      this.billofladingdropdown = false
      this.active = false
      this.viewdetail = true;
      this.allStatus = response
      this.blStatusDetils = response.events;
      this.getSystem = response.systems;
      this.updateStatus(this.tarjaDetils ? 'TH' : response.systems[0])
      this.changeDetectorRef.detectChanges();
    }, (error) => {
      this.toastrService.error('Ha ocurrido un error, favor intente de nuevo. Si el error persiste , comunicarse al correo mardomgo@mardom.com');
    })
  }

  getTarjaDetilsList() {
    if (this.tarjasList.length != 0) {
      this.dashboardServices.getTarjaDetils(this.tarjasList).subscribe(response => {
        this.tarjaDetils = response;
        this.blHijoNumber = this.tarjaDetils ? this.tarjaDetils[0].blNumber : ''
        this.almacendesconsolidacion = true;
        this.getBlDetailsStatus()
        this.changeDetectorRef.detectChanges();
      }, (error) => {
        this.toastrService.error('Ha ocurrido un error, favor intente de nuevo. Si el error persiste , comunicarse al correo mardomgo@mardom.com');
      })
    } else {
      this.blHijoNumber = ''
      this.tarjaDetils = ''
      this.getBlDetailsStatus();
    }

  }

  updateStatus(type) {
    if (type == 'TH') {
      this.blStatusDetilsHorizontal = this.allStatus.thevents;
      this.blStatusDetilsHorizontal = this.sortByDateH();
      this.removestatus();
      this.blStatusDetils = this.allStatus.thevents;
      this.blStatusDetils = this.sortByDate();
      this.firstDate = this.blStatusDetilsHorizontal[0];
      this.lastDate = this.blStatusDetilsHorizontal[this.blStatusDetilsHorizontal.length - 1];
      this.firstDate = this.blStatusDetils[0];
      this.lastDate = this.blStatusDetils[this.blStatusDetils.length - 1];
      this.viewdetail = true;
      this.verticalstatusremove()
      this.almacendesconsolidacion = true;
      this.blThStatusDetils = [];
    } else if (type == 'WINDIP') {
      this.blStatusDetilsHorizontal = this.allStatus.windipevents;
      this.blStatusDetilsHorizontal = this.sortByDateH();
      this.removestatus();
      this.updatestatus();
      this.blStatusDetils = this.allStatus.windipevents;
      this.blStatusDetils = this.sortByDate();
      this.firstDate = this.blStatusDetilsHorizontal[0];
      this.lastDate = this.blStatusDetilsHorizontal[this.blStatusDetilsHorizontal.length - 1]
      this.firstDate = this.blStatusDetils[0];
      this.lastDate = this.blStatusDetils[this.blStatusDetils.length - 1]
      this.viewdetail = true;
      this.gestionaduanal = true;
      this.blThStatusDetils = [];
      this.verticalstatusremove();
    }
    // else if (type == 'Camiencito') {
    //   this.firstDate = ''
    //   this.lastDate = '';
    //   this.blStatusDetilsHorizontal = [];
    //   this.blStatusDetils = [];
    //   this.transportData = this.allStatus.camiencitoevents;
    //   this.transpterrestre = true
    // }
    else if (type == 'Silver Bullet' || type == 'Camiencito') {
      if (type == 'Silver Bullet') {
        this.blStatusDetilsHorizontal = this.allStatus.silverbulletevents;
        this.blStatusDetilsHorizontal = this.sortByDateH();
        this.blStatusDetils = this.allStatus.silverbulletevents;
        this.removestatus();
        this.blStatusDetils = this.sortByDate();
      } else if (type == 'Camiencito') {
        this.firstDate = ''
        this.lastDate = '';
        this.blStatusDetilsHorizontal = [];
        this.blStatusDetils = [];
        this.transportData = this.allStatus.camiencitoevents;
        this.transpterrestre = true
      }

      this.transpterrestre = true
    } else if (type == 'WIS') {
      this.blStatusDetilsHorizontal = this.allStatus.wisevents;
      this.blStatusDetilsHorizontal = this.sortByDateH();
      this.removestatus();
      this.blStatusDetils = this.allStatus.wisevents;
      this.blStatusDetils = this.sortByDate();
      this.viewdetail = true;
      this.almacengeneral = true
      this.verticalstatusremove()
    } else if (type == 'POCONTROL') {
      this.blStatusDetilsHorizontal = this.allStatus.poevents;
      this.blStatusDetils = this.allStatus.poevents;
      this.blStatusDetils = this.sortByDate();
      this.viewdetail = true;
      if (this.poPort == 'Aerea') {
        this.transpaereo = true;
      } else if (this.poPort == 'Maritima') {
        this.transpmaritima = true;
      }
    } else if (type == 'TMS') {
      this.firstDate = ''
      this.lastDate = '';
      this.blStatusDetilsHorizontal = [];
      this.blStatusDetils = [];
      this.transportData = this.allStatus.tmSevents;
      this.transpterrestre = true
    } else {
      this.blStatusDetilsHorizontal = []
      this.blStatusDetils = [];
      this.blThStatusDetils = [];
      this.firstDate = '';
      this.lastDate = '';
    }
  }

  updatestatus() {
    let blstat = this.blStatusDetilsHorizontal.filter(a => {
      if (a.system == 'WINDIP') {
        return (
          a.trackstatus != 'Verificado'
        )
      }
    });
    this.blStatusDetilsHorizontal = blstat;
  }

  removestatus() {
    let blStatusDetilss = this.blStatusDetilsHorizontal.filter(i => {
      return (
        (i.trackstatus != 'Facturado' && i.trackstatus != 'Solicitud De Gestion Aduanal Creada' && i.trackstatus != 'En Clasificacion Arancelaria' && i.trackstatus != 'DesParaPago' && i.trackstatus != 'En proceso de verificación' && i.trackstatus != 'Valores enviados para pago')
      );
    });
    this.blStatusDetilsHorizontal = blStatusDetilss;
  }

  verticalstatusremove() {
    let verticalstatremove = this.blStatusDetils.filter(b => {
      return (
        (b.trackstatus != 'Facturado' && b.trackstatus != 'DesParaPago' && b.trackstatus != 'En proceso de verificación')
      );
    });
    this.blStatusDetils = verticalstatremove;
  }

  sortByDate() {
    return this.blStatusDetils.sort((a, b) => {
      return <any>new Date(b.trackdate) - <any>new Date(a.trackdate);
    });
  }

  sortByDateH() {
    return this.blStatusDetilsHorizontal.sort((a, b) => {
      return <any>new Date(b.trackdate) - <any>new Date(a.trackdate);
    });
  }


  cancel() {
    this.modalService.dismissAll();
    // let switchRound = document.getElementById("togBtn-" + this.impedementActiveIndex) as HTMLInputElement
    // switchRound.checked = false;
  }

  share(order) {
    let sharedtype = '';
    if (order.system == 'WINDIP') {
      sharedtype = 'bl';
    } else if (order.system == 'WIS') {
      sharedtype = 'agenda';
    }
    // else if (order.system == 'TH') {
    //   sharedtype = 'contenedor';
    // } else if(order.system == 'TH'){
    //   sharedtype = 'tarja';
    // }
    else {
      sharedtype = 'bl';
    }

    var blString = order.blNumber;
    blString = blString.replace(/\//g, "%2F");
    order.blNumber = blString;

    this.sharelink = window.location.origin + "/order/" + order.blNumber + "/" + sharedtype;
    this.selectedData = order
    this.modalService.open(this.sharedata, {
      size: 'm', backdrop: 'static', centered: true
    });
  }

  searchPedidoEnCruso() {
    this.page = 1
    let datefrom = this.searchForm.controls.FromDate.value;
    let dateto = this.searchForm.controls.ToDate.value;
    // let routeurl = '/order/'+this.searchForm.controls.Refrenceno.value
    this.billofladingdropdown = false;
    this.active = false;
    this.rightsidepanel = false
    // this.router.navigate([routeurl]);
    if ((datefrom || dateto) && (datefrom && dateto) && (datefrom > dateto)) {
      this.toastrService.info("La fecha en 'Fecha Hasta' debe de ser mayor a 'Fecha Desde'.");
    } else if (this.searchForm.controls.refrenceType.value) {
      //if (this.searchForm.controls.Refrenceno.value) {
      this.getOrderInProgressList();
      /* } else {
        this.toastrService.info("Por favor ingrese Referencia");
      } */
    } else {
      this.getOrderInProgressList();
      // this.toastrService.error("Por favor ingrese los campos obligatorios");
    }
  }

  getFavoritos() {
    this.dashboardServices.getFavoritosList(this.page, this.size, this.selectedCompanyID).subscribe(response => {
      this.orderInProgressList = response.response
      this.orderInProgressList.Total = response['total'];
      this.notRows = response['total'] == '0';
      this.almacendesconsolidacion = false;
      this.transpterrestre = false;
      this.viewdetail = false;
      this.orderInProgressList.forEach(element => {
        element['addToFavourite'] = true
      });
      this.changeDetectorRef.detectChanges();

    }, (error) => {
      this.toastrService.error('Ha ocurrido un error, favor intente de nuevo. Si el error persiste , comunicarse al correo mardomgo@mardom.com');
    })
  }

  private getOrderInProgressList() {
    let payload = {
      FromDate: this.searchForm.controls.FromDate.value,
      ToDate: this.searchForm.controls.ToDate.value,
      Refrenceno: this.searchForm.controls.Refrenceno.value ? this.searchForm.controls.Refrenceno.value : this.blNumber,
      Status: "",
      refrencetype: this.searchForm.controls.refrenceType.value != null ? this.searchForm.controls.refrenceType.value : this.sharedtype,
      //refrencetype: this.sharedtype,
      orderon: this.orderon ? this.orderon : 'dt',
      orderby: this.sortOrder ? this.sortOrder : 'desc',
      startswith: this.search
    }
    this.dashboardServices.getOrderInProgressList(this.page, this.size, payload).subscribe(response => {
      this.orderInProgressList = [];
      if (payload.Refrenceno && response.response.length > 0) {
        this.orderInProgressList.push(response.response[0])
      } else {
        this.orderInProgressList = response.response
      }
      this.orderInProgressList.Total = response['total'];
      this.notRows = response['total'] == '0';
      this.almacendesconsolidacion = false;
      this.transpterrestre = false;
      this.viewdetail = false;
      this.setFavoritos(response.favourites);
      this.poPort = ''
      if (response.response.length > 0) {
        response.response.forEach(element => {
          if (element.system == 'POCONTROL')
            this.poPort = element.loadType ? element.loadType : 'Maritima'
        });
      }
      if (this.blNumber) {
        this.openDetailsBill(0, this.orderInProgressList[0]);
      }
      this.changeDetectorRef.detectChanges();
    }, (error) => {
      this.toastrService.error('Ha ocurrido un error, favor intente de nuevo. Si el error persiste , comunicarse al correo mardomgo@mardom.com');
    })
  }

  private setFavoritos(favourites) {
    if (favourites) {
      this.orderInProgressList.forEach(element => {
        let blNumber = favourites.find(item => item == element.blNumber);
        if (blNumber) {
          element['addToFavourite'] = true
        }
      });
    }
  }

  public updateImpediment() {
    let paylod = {}
    paylod = {
      id: this.selectedData.id,
      Impediment: true
    }
    this.dashboardServices.updateImpediment(paylod).subscribe((data) => {
      this.changeDetectorRef.detectChanges();
      this.toastrService.success("Actualización de estado de Impedimento con éxito")
      this.modalService.dismissAll();
    }, (error) => {
      this.toastrService.error('Ha ocurrido un error, favor intente de nuevo. Si el error persiste , comunicarse al correo mardomgo@mardom.com');
    })
  }

  selectTarja(traja) {
    this.selectedTraja = traja
  }

  onPageChange(item: number) {
    this.page = item;
    if (this.title == 'Favoritos') {
      this.getFavoritos()
    } else {
      this.getOrderInProgressList();
    }
  }

  sortList(event, order) {
    if (order == 'asc') {
      this.sortOrder = 'asc'
    } else if (order == 'desc') {
      this.sortOrder = 'desc'
    } else if (event.target.value == 'bl') {
      this.orderon = 'bl'
      this.sortOrder = 'asc'
      this.getOrderInProgressList();
    } else if (event.target.value == 'dt') {
      this.orderon = 'dt';
      this.sortOrder = 'desc'
      this.getOrderInProgressList();
    }
    event.stopPropagation();
  }

  selectSize(event) {
    this.size = event.target.value;
    this.getOrderInProgressList();
  }

  clearSort() {
    this.search = ""
    this.sortOrder = "desc"
    this.orderon = "dt"
    this.page = 1
    this.getOrderInProgressList();
  }

  clearFilter() {
    this.page = 1
    this.searchForm.controls.FromDate.setValue('');
    this.searchForm.controls.ToDate.setValue('');
    this.searchForm.controls.Refrenceno.setValue('');
    this.searchForm.controls.refrenceType.setValue('bl');
    this.searchForm.controls.status.setValue(this.status);
    this.sortOrder = '';
    this.orderon = '';
    this.search = '';
    this.indexno = -1;
    this.almacengeneral = false;
    this.gestionaduanal = false;
    this.almacendesconsolidacion = false;
    this.transpterrestre = false;
    this.transpaereo = false;
    this.transpmaritima = false;
    this.blNumber = '';
    this.rightsidepanel = false
    this.router.navigate(['/order']);
    if (this.title == 'Favoritos') {
      this.getFavoritos()
    } else {
      this.getOrderInProgressList();
    }
  }

  previousPage() {
    if (this.viewdetail == true) {
      this.viewdetail = false;
      this.blexpandupdates = true;
      this.billofladingdropdown = true;
      this.active = !this.active
      this.firstDate = '';
      this.lastDate = '';
      this.gestionaduanal = false;
      this.almacendesconsolidacion = false;
      this.blNumber = '';
      this.rightsidepanel = true;
      this.orderinprogress.sendData(
        {
          calificar: false,
          blnumber: ""
        }
      );
    } else if (this.billofladingdropdown == true) {
      this.billofladingdropdown = false;
      this.rightsidepanel = false;
      this.getOrderInProgressList();
    } else {
      this.router.navigate(['login']);
    }
  }

  genrateForm() {
    this.searchForm = this.formBuilder.group({
      status: [''],
      FromDate: '',
      ToDate: '',
      refrenceType: ['bl'],
      Refrenceno: ''
    });
  }



}
